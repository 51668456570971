/* Local */
// const fileURL = 'http://localhost/traullit-lagerplanerare/file.php';

/* Live */

const URLbase = 'https://peuwlplugins.com/wp-json/traullit';


export const inventoryURL = URLbase+'/inventory/'; 
export const historyURL = URLbase + '/history/'; 

