import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="container">
    <h1>Sidan du sökte hittades inte.</h1>
    <p>
      Klicka <Link to="/">här</Link> för att komma till startsidan eller <a href="mailto:mail@juliusosbeck.com">här</a> för att kontakta hemsidans utvecklare (Julius Osbeck).
    </p>
  </div>
);

export default NotFound;