import React, { Component } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';


class ProductRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            id: ''
        };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
    }

    componentWillReceiveProps(){
        if(this.props.reset === 1){
            this.setState({value: ''}, () => {
                console.log('Refreshed value (state) in child componant.');
            });   
        }
    }

    inputChangeHandler(event){   
        this.setState({value: event.target.value});
    }

    render(){
        if(this.props.step === 'step1'){
            return(
            <TableRow key={this.props.id}>
                <TableCell>{this.props.sku}</TableCell>
                <TableCell>{this.props.name}</TableCell>
                <TableCell className="input-field-td" align="right" data-id={this.props.id} >
                    <TextField
                        style={{textAlign: 'right'}} 
                        data-id={this.props.id} 
                        placeholder="Antal"
                        value={this.state.value}
                        onChange={this.inputChangeHandler} 
                        type="number" 
                        min="0"
                        className="input-field"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        />
                </TableCell>
                <TableCell className="antal" align="right">{this.props.qty1}</TableCell>
            </TableRow>
            ); 
        }
        if(this.props.step === 'step2'){
            return(
                <TableRow key={this.props.id}>
                <TableCell>{this.props.sku}</TableCell>
                <TableCell>{this.props.name}</TableCell>
                <TableCell className="input-field-td" align="right" data-id={this.props.id} >
                    <TextField
                        style={{textAlign: 'right'}} 
                        data-id={this.props.id} 
                        placeholder="Antal"
                        value={this.state.value}
                        onChange={this.inputChangeHandler} 
                        type="number" 
                        min="0"
                        className="input-field"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                        />
                </TableCell>
                <TableCell className="antal" align="right">{this.props.qty1}</TableCell>
            </TableRow>
            );
        }
        if(this.props.step === 'step3'){
            return(
                <TableRow key={this.props.id}>
                    <TableCell>{this.props.sku}</TableCell>
                    <TableCell>{this.props.name}</TableCell>
                    <TableCell className="input-field-td" align="right" data-id={this.props.id} >
                        <TextField
                            className="input-field"
                            style={{textAlign: 'right'}} 
                            data-id={this.props.id} 
                            placeholder="Antal"
                            value={this.state.value}
                            onChange={this.inputChangeHandler} 
                            type="number" 
                            min="0"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            margin="normal"
                            />
                    </TableCell>
                    <TableCell className="antal" align="right">{this.props.qty2}</TableCell>
                </TableRow>
            );
        }
    }
};

export default ProductRow;