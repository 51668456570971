import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ProductRow from './ProductRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { historyURL, inventoryURL } from './fileUrl'; /* Url for the read/write-file */
let tempJson;
let reset = 0;

class PagePlocka extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: '',
            value: '',
            loading: true,
        }
        this.inputContainer = React.createRef();
    }
    componentDidMount(){
        this.updateJSON()
        setInterval( e => this.updateJSON(), 2500)
        setTimeout(() => this.setState({loading: false}), 500);
    }
    updateJSON = () => {   
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            this.setState({products: JSON.parse(data)}); 
            console.log('Content from Träullit-API updated. (Ut)');
        }).catch(err => {
            console.log('Error while trying to update Träullit-API.')
        });
    }
    updateChangeLog = (old, updated) => { // History
        const tempDate = new Date();
        const date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate() +' '+ tempDate.getHours()+':'+ tempDate.getMinutes()+':'+ tempDate.getSeconds();
        let changes = '';
        old = JSON.parse(old);
        updated = JSON.parse(updated);
        if(old.qty2 !== updated.qty2){ 
            changes =  updated.name + ' (' + updated.sku + ') - Saldot för "Torkat" ändrades från '+ old.qty2 + ' till ' + updated.qty2 +' (Plocka från torkat lager).';
            const json = {date: date, changes: changes, class: 'history-plocka'};
            fetch(historyURL+'?add=' + encodeURIComponent(JSON.stringify(json))).then(response => {
                return response.json();
            }).then(data => {     
                console.log('History log updated.')
            }).catch(err => {
                console.log('Error while trying to log. 1');
            });    
        }
    }
    updateButtonClick = () => {
        this.updateList();
    }
    updateList = () => {
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            tempJson = JSON.parse(data);
            let value;
            let id;
            let old; // History
            let updated; // History
            const rowContainer = this.inputContainer.current.children[2].children[0].children[1];
            for(let i = 0; i < rowContainer.children.length; i++){
                let input = rowContainer.children[i].children[2].children[0].children[0].children[0];
                value = input.value;
                input.value = '';
                if(!value){ value = 0; }
                id = rowContainer.children[i].children[2].dataset.id
                for (let i in tempJson) {
                    if (tempJson[i].id === id) {
                        if(tempJson[i].qty2 - parseInt(value) >= 0){
                            old = JSON.stringify(tempJson[i]); // History
                            tempJson[i].qty2 = (tempJson[i].qty2 - parseInt(value));
                            updated = JSON.stringify(tempJson[i]); // History
                            this.updateChangeLog(old, updated); // History
                            break; 
                        }
                    }
                }   
            } 
                
            this.setState({loading: true}, () => { 
                fetch(inventoryURL+'?update=' + encodeURIComponent(JSON.stringify(tempJson))).then(response => {
                    return response.json();
                }).then(data => {     
                
                    reset = 1;
                    setTimeout(() => { 
                        this.setState({
                            products: JSON.parse(data), loading: false
                        }, () => {
                            reset = 0;
                            console.log('Träullit-API updated with new values.');
                        });   
                    }, 800)
                }).catch(err => {
                    console.log('Error while trying to update tempJSON inside nested fetch-function.')
                });    
            });
            
            }).catch(err => {
                console.log('Error while trying to update tempJSON.')
        });    
    }
    render(){
    
    let ProductFeed;
    if(this.state.products !== ''){
        ProductFeed = this.state.products.map((product, index) =>
        
            <ProductRow
                key={product.id}
                step='step3' // tredje steget (ut från fabrik)
                id={product.id}
                sku={product.sku}
                name={product.name}                    
                qty1={product.qty1}
                qty2={product.qty2}
                reset={reset}
            />    
        );  
    }  
    if(this.state.loading){
        return(
            <div className="container pad-bot page-plocka" ref={this.inputContainer}>
                <Typography variant="h4" gutterBottom>
                    Plocka från torkat lager
                </Typography>
                <br/>
                <Paper className="paper-main">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Artikelnummer</TableCell>
                                <TableCell>Produktnamn</TableCell>
                                <TableCell align="center">Lägg till</TableCell>
                                <TableCell className="antal" align="right">Antal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow></TableRow>
                        </TableBody>
                    </Table>
                </Paper>
                <LinearProgress className="plocka-load"  />   
            </div>
        );
    }else{
        return(
            <div className="container pad-bot page-plocka" ref={this.inputContainer}>
                <Typography variant="h4" gutterBottom>
                    Plocka från torkat lager
                </Typography>
                <br/>
                <Paper className="paper-main">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Artikelnummer</TableCell>
                                <TableCell>Produktnamn</TableCell>
                                <TableCell align="center">Lägg till</TableCell>
                                <TableCell className="antal" align="right">Antal</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {ProductFeed}
                        </TableBody>
                    </Table>
                </Paper>
                <br/><br/>
                <Button variant="contained" size="large" color="primary" onClick={this.updateButtonClick}>
                    Uppdatera
                </Button>
            </div>
            );
        }
    }
};

export default PagePlocka;
