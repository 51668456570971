import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./style.css";
import "./burger.css";
import NotFound from "./NotFound";
import PageUt from "./PageUt";
import PageFyll from "./PageFyll";
import PagePlocka from "./PagePlocka";
import PageHistory from "./PageHistory";
import PageSettings from "./PageSettings";
import PageDiagram from "./PageDiagram";
import PageKvm from "./PageKvm"; 
/*
import PageAkustik from "./PageAkustik";
import PageAkustikEdit from "./PageAkustikEdit"; */
import ReactBurgerMenu from "./ReactBurgerMenu";

const App = () => (

  <BrowserRouter>
    <div className="app">

    <ReactBurgerMenu />
    <div id="page-wrap">
      <div className="pusher"></div>
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route exact path="/" component={PageUt} />
            <Route path="/fyll" component={PageFyll} />
            <Route path="/plocka" component={PagePlocka} />
            <Route path="/history-log" component={PageHistory} />
            <Route path="/settings" component={PageSettings} />
            <Route path="/diagram" component={PageDiagram} />
            <Route path="/kvm" component={PageKvm} />
            <Route component={NotFound} />
          </Switch>
        )}
      />
    </div>
    </div>

  </BrowserRouter>
);

export default App;

        /*

            <Route path="/diagram" component={PageDiagram} />
            <Route path="/kvm" component={PageKvm} />
            <Route path="/akustik" component={PageAkustik} />
            <Route path="/akustik-edit" component={PageAkustikEdit} />
            */