import React, { Component } from 'react';
import { HorizontalBar, Line } from 'react-chartjs-2';
import { historyURL, inventoryURL } from './fileUrl'; /* Url for the read/write-file */

let lastData = '';

export default class Button extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: '',
            value: ''
        }
    }
    componentDidMount(){
        this.updateJSON();
        setInterval(() => {
            
            fetch(inventoryURL).then(response => {
                return response.json();
            }).then(data => {
                if( data !== lastData){
                    this.setState({
                        products: JSON.parse(data)
                    }, () => {
                        lastData = data;
                    });  
                }else{
                    lastData = data;
                }
            }).catch(err => {
                console.log('Error while trying to check if graph needs an update.')
            });
        }, 5000);
    }

    updateJSON = () => {   
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            this.setState({products: JSON.parse(data)}); 
            console.log('Content from Träullit-API updated.');
        }).catch(err => {
            console.log('Error while trying to update Träullit-API.')
        });
    }


    render() {
        const json = this.state.products;
        let qty1 = [];
        let qty2 = [];
        let kvm_otorkat = [];
        let kvm_torkat = [];
        let kvm_totalt = [];
        let labels = [];

        for(let i = 0; i < json.length; i++){
            qty1.push(json[i].qty1); 
            qty2.push(json[i].qty2); 
            labels.push(json[i].sku + ' - ' + json[i].name); 
            kvm_otorkat.push(Math.round((json[i].per_pall * json[i].qty1) * 100) / 100);
            kvm_torkat.push(Math.round((json[i].per_pall * json[i].qty2) * 100) / 100);
            kvm_totalt.push(Math.round((Math.round((json[i].per_pall * json[i].qty1) * 100) / 100) + (Math.round((json[i].per_pall * json[i].qty2) * 100) / 100))); 
        }

        console.log(json);
        

        let dataBarKvm = {
            type: 'bar',
            labels: labels,
            datasets: [
                {
                    label: 'Kvm torkat',
                    backgroundColor: '#135c12',
                    borderColor: '#135c12',
                    borderWidth: 1,
                    hoverBackgroundColor: '#4e74b5',
                    hoverBorderColor: '#4e74b5',
                    data: kvm_torkat,
                
                },
                {
                    label: 'Kvm otorkat',
                    backgroundColor: '#183e80',
                    borderColor: '#183e80',
                    borderWidth: 1,
                    hoverBackgroundColor: '#8FC48D',
                    hoverBorderColor: '#8FC48D',
                    data: kvm_otorkat,
                },
                {
                    label: 'Tot. kvm',
                    backgroundColor: '#a3652e',
                    borderColor: '#a3652e',
                    borderWidth: 1,
                    hoverBackgroundColor: '#d18e52',
                    hoverBorderColor: '#d18e52',
                    data: kvm_totalt,
                }
            ]
        };

        let dataBar = {
            type: 'bar',
            labels: labels,
            datasets: [
                {
                    label: 'Råformat otorkat',
                    backgroundColor: '#325694',
                    borderColor: '#325694',
                    borderWidth: 1,
                    hoverBackgroundColor: '#4e74b5',
                    hoverBorderColor: '#4e74b5',
                    data: qty1
                },
                {
                    label: 'Torkat',
                    backgroundColor: '#459644',
                    borderColor: '#459644',
                    borderWidth: 1,
                    hoverBackgroundColor: '#8FC48D',
                    hoverBorderColor: '#8FC48D',
                    data: qty2
                },
            ]
        };
        var options = {
            animation: false,
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                stacked: false,
                gridLines: {
                    display: true,
                    color: "rgba(255,99,132,0.2)"
                }
                }],
                xAxes: [{
                    gridLines: {
                    display: false
                    }
                }]
            }
        };

    return (
        <div className="chart-container" style={{position: 'relative', height: '80vh', maxWidth: '1100px', width: '90vw'}}>
            <HorizontalBar options={options} data={dataBar}/>
            <h4 class="kvm-title MuiTypography-root-1 MuiTypography-h4-16 MuiTypography-gutterBottom-28">Kvadratmeter</h4>
            <HorizontalBar options={options} data={dataBarKvm}/>
        </div>
    );

    }
}

