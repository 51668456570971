import React, { Component } from "react";
import Graph from "./Graph";
import Typography from '@material-ui/core/Typography';

class PageDiagram extends Component {
    render(){
        return(
            <div className="container page-diagram">
                <Typography variant="h4" gutterBottom>
                    Diagram
                </Typography>
                <Graph name="Lagerstatus" />
            </div>
        );
    }
};

export default PageDiagram;

