import React from 'react';
import { slide as Menu } from 'react-burger-menu'

class ReactBurgerMenu extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }


    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }
    
    closeMenu () {
        this.setState({menuOpen: false})
    }

    toggleMenu () {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    render () {
        return (
            <div>
                <div className="brand"><span>Träullit:</span> Lagerplanerare</div>
                <Menu 
                    pageWrapId={ "page-wrap" } 
                    right 
                    noOverlay
                    isOpen={this.state.menuOpen}
                    onStateChange={(state) => this.handleStateChange(state)}
                >
                    <a href="/">Ut från fabrik</a>
                    <a href="/fyll">Fyll på torkat</a>
                    <a href="/plocka">Plocka från torkat</a>
                    {<br/>}
                    
                    <a href="/kvm">Kvm</a>
                    <a href="/diagram">Diagram</a>
                    <a href="/settings">Inställningar</a>
                    {<br/>}
                    <a href="/history-log">Historik</a>
                    {<br/>}
                    <a href="/akustik">Akustiklager</a>
                </Menu>
            </div>
        )
        }
    }

export default ReactBurgerMenu;