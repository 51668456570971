import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SettingsRow from './SettingsRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { historyURL, inventoryURL } from './fileUrl'; /* Url for the read/write-file */
let tempJson;
let reset = 0;

class PageSettings extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            products: '',
            value: '',
            loading: true
        }
        this.inputContainer = React.createRef();
    }

    componentDidMount(){
        this.updateJSON();
        setTimeout(() => { 
            this.setState({loading: false});
        }, 1000);
    }

    updateJSON = () => {   
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            this.setState({products: JSON.parse(data)}); 
            console.log('Content from Träullit-API updated.');
        }).catch(err => {
            console.log('Error while trying to update Träullit-API.')
        });
    }

    updateList = () => {
        tempJson = [];
        let id;
        let sku;
        let name;
        let qty1;
        let qty2;
        let per_pall;
        let prio;

        const rowContainer = this.inputContainer.current.children[2].children[0].children[1];
        for(let i = 0; i < rowContainer.children.length; i++){
            id = rowContainer.children[i].children[3].children[0].dataset.id;
            sku = rowContainer.children[i].children[0].children[0].children[0].children[0].value;
            name = rowContainer.children[i].children[1].children[0].children[0].children[0].value;
            qty1 = rowContainer.children[i].children[2].children[0].children[0].children[0].value;
            if(!qty1){ qty1 = 0; }    
            qty2 = rowContainer.children[i].children[3].children[0].children[0].children[0].value;
            if(!qty2){ qty2 = 0; }
            per_pall = rowContainer.children[i].children[4].children[0].children[0].children[0].value;
            if(!per_pall){ per_pall = 60.48; }
            prio = rowContainer.children[i].children[5].children[0].children[0].children[0].value;
            if(!prio){ prio = 30; }
            tempJson.push({ 
                id: id, 
                sku: sku, 
                name: name, 
                qty1: parseInt(qty1), 
                qty2: parseInt(qty2),
                per_pall: per_pall,
                prio: prio
            });  
        }
    
        this.setState({loading: true}, () => { 
            fetch(inventoryURL+'?update=' + encodeURIComponent(JSON.stringify(tempJson))).then(response => {
                return response.json();
            }).then(data => {     
                setTimeout(() => {
                    this.setState({
                        products: JSON.parse(data), loading: false
                    }, () => {          
                        console.log('Träullit-API updated with new values.');
                    });   
                }, 800);
            }).catch(err => {
                console.log('Error while trying to update tempJSON inside nested fetch-function.')
            });     
        });
        
    }

    addProduct = () => {
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            fetch(inventoryURL+'?add=new').then(response => {
                return response.json();
            }).then(data => {     
                this.setState({products: JSON.parse(data)}); 
                console.log('Product added to JSON-file.')
            }).catch(err => {
                console.log('Error while trying to add a product.');
            });    
        }).catch(err => {
            console.log('Error while trying to add a product.');
        });    
    }

    deleteProduct = (id) => {
        console.log(id);
        console.log(inventoryURL+'?delete=' + id);
        fetch(inventoryURL+'?delete=' + id).then(response => {
            return response.json();
        }).then(data => {     
            this.setState({products: JSON.parse(data)}); 
            console.log('Product deleted from JSON-file.')
        }).catch(err => {
            console.log('Error while trying to delete a product.');
        });    
    }

    render(){
        let ProductFeed = '';
        if(this.state.products !== ''){
            ProductFeed = this.state.products.map((product, index) =>
            <SettingsRow
                    key={product.id}
                    id={product.id}
                    name={product.name}  
                    sku={product.sku}                   
                    qty1={product.qty1}
                    qty2={product.qty2}
                    prio={product.prio}
                    per_pall={product.per_pall}
                    reset={reset}
                    delete={this.deleteProduct}
            />);  
        }
        
        if(this.state.loading){
            return(
                <div className="container pad-bot" ref={this.inputContainer}>
                    <Typography variant="h4" gutterBottom>
                        Redigera produkter
                    </Typography>
                    <br/>
                    <Paper className="paper-main">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Artikelnummer</TableCell>
                                    <TableCell>Produktnamn</TableCell>
                                    <TableCell align="right">Från lager</TableCell>
                                    <TableCell align="right">Torkat</TableCell>
                                    <TableCell align="right">Antal per pall</TableCell>
                                    <TableCell align="right">Visningsprio</TableCell>
                                    <TableCell align="right">Radera</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow></TableRow>
                            </TableBody>
                        </Table>
                    </Paper>
                    <LinearProgress />   
                </div>
            );
        }else{

        return(
            <div className="container pad-bot page-settings" ref={this.inputContainer}>
            <Typography variant="h4" gutterBottom>
                Redigera produkter
            </Typography>
            <br/>
            <Paper className="paper-main settings">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Artikelnummer</TableCell>
                            <TableCell>Produktnamn</TableCell>
                            <TableCell align="right">Från lager</TableCell>
                            <TableCell align="right">Torkat</TableCell>
                            <TableCell align="right">Antal per pall</TableCell>
                            <TableCell align="right">Visningsprio</TableCell>
                            <TableCell align="right">Radera</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{ProductFeed}</TableBody>
                </Table>
            </Paper>
            <br/><br/>
            <Button style={{margin: '5px'}}variant="contained" size="large" color="primary" onClick={this.addProduct}>
                Lägg till produkt
            </Button>
            <Button style={{margin: '5px'}} variant="contained" size="large" color="primary" onClick={this.updateList}>
                Uppdatera
            </Button>
        </div>
        );

        }
    }
};

export default PageSettings;

// <Button variant="contained" size="large" color="primary" onClick={this.updateList}>