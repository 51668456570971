import React, { Component } from "react";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

class SettingsRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            name: this.props.name,
            sku: this.props.sku,
            qty1: this.props.qty1,
            qty2: this.props.qty2,
            per_pall: this.props.per_pall,
            prio: this.props.prio,
        };
        this.inputChangeHandlerName = this.inputChangeHandlerName.bind(this);
        this.inputChangeHandlerSku = this.inputChangeHandlerSku.bind(this);
        this.inputChangeHandlerQty1 = this.inputChangeHandlerQty1.bind(this);
        this.inputChangeHandlerQty2 = this.inputChangeHandlerQty2.bind(this);
        this.inputChangeHandlerPerPall = this.inputChangeHandlerPerPall.bind(this);
        this.inputChangeHandlerPrio = this.inputChangeHandlerPrio.bind(this);
    }

    deleteInput = () => {
        this.props.delete(this.props.id);
    }
    inputChangeHandlerName(event){           
        this.setState({name: event.target.value});
    //    Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-name');
    }
    inputChangeHandlerSku(event){           
        this.setState({sku: event.target.value});
    //    Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-name');
    }
    inputChangeHandlerQty1(event){           
        this.setState({qty1: event.target.value});
    //   Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-qty1');
    }
    inputChangeHandlerQty2(event){           
        this.setState({qty2: event.target.value});
    //    Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-qty2');
    }
    inputChangeHandlerPerPall(event){           
        this.setState({per_pall: event.target.value});
    //    Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-qty2');
    }
    inputChangeHandlerPrio(event){           
        this.setState({prio: event.target.value});
    //    Global.updateProduct(event.target.dataset.id, event.target.value, 'settings-qty2');
    } 
    render(){
        return(<TableRow key={this.props.id}><TableCell>
                    <TextField align="left"
                        value={this.state.sku}
                        onChange={this.inputChangeHandlerSku}
                        type="text"
                        InputLabelProps={{shrink: true}}
                        margin="normal"
                        placeholder="Artikelnummer"
                        />
                </TableCell>
                <TableCell align="left">
                    <TextField
                        data-id={this.props.id}
                        value={this.state.name}
                        onChange={this.inputChangeHandlerName}
                        type="text"
                        InputLabelProps={{shrink: true}}
                        margin="normal"
                        placeholder="Produktnamn"
                        />
                </TableCell>
                <TableCell align="right" data-id={this.props.id} >
                    <TextField
                        data-id={this.props.id}
                        value={this.state.qty1}
                        onChange={this.inputChangeHandlerQty1}
                        type="number"
                        min="0"
                        InputLabelProps={{shrink:true}}
                        margin="normal"
                        />
                </TableCell>
                <TableCell align="right">
                    <TextField
                        data-id={this.props.id}
                        value={this.state.qty2}
                        onChange={this.inputChangeHandlerQty2}
                        type="number"
                        min="0"
                        InputLabelProps={{shrink:true}}
                        margin="normal"
                        />
                </TableCell>
                <TableCell align="right">
                    <TextField
                        data-id={this.props.id}
                        value={this.state.per_pall}
                        onChange={this.inputChangeHandlerPerPall}
                        type="number"
                        min="1"
                        step="0.01"
                        InputLabelProps={{shrink:true}}
                        margin="normal"
                        />
                </TableCell>
                <TableCell align="right">
                    <TextField
                        data-id={this.props.id}
                        value={this.state.prio}
                        onChange={this.inputChangeHandlerPrio}
                        type="number"
                        min="1"
                        step="1"
                        InputLabelProps={{shrink:true}}
                        margin="normal"
                        />
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="Delete" onClick={this.deleteInput}>
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
    );}};

export default SettingsRow;