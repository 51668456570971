import React, { Component } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { historyURL, inventoryURL } from './fileUrl'; /* Url for the read/write-file */



class PageHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            products: '',
            value: '',
            loading: true,
        }
        this.inputContainer = React.createRef();
    }
    componentDidMount(){
        this.updateJSON()
        setInterval( e => this.updateJSON(), 2500)
        setTimeout(() => this.setState({loading: false}), 500);
    }
    updateJSON = () => {   
        fetch(inventoryURL).then(response => {
            return response.json();
        }).then(data => {
            console.log(JSON.parse(data));
            this.setState({products: JSON.parse(data)}); 
            console.log('Content from Träullit-API updated. (Ut)');
        }).catch(err => {
            console.log('Error while trying to update Träullit-API.')
        });
    }

    render(){
    let ProductFeed;
    if(this.state.products !== ''){
        ProductFeed = this.state.products.map((product, index) =>
            <TableRow key={index}>
                <TableCell align="left">{product.sku}</TableCell>
                <TableCell align="left">{product.name}</TableCell>
                <TableCell align="right">{ ((parseInt(product.qty1)) * product.per_pall).toFixed(1)} m2</TableCell>
                <TableCell align="right">{ ((parseInt(product.qty2)) * product.per_pall).toFixed(1)} m2</TableCell>
            </TableRow>
        );  
    }  
    if(this.state.loading){
        return(
            <div className="container pad-bot" ref={this.inputContainer}>
                <Typography variant="h4" gutterBottom>
                    Kvm
                </Typography>
                <br/>
                <Paper className="paper-main">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Artikelnummer</TableCell>
                                <TableCell align="left">Namn</TableCell>
                                <TableCell align="right">Otorkat m2</TableCell>
                                <TableCell align="right">Torkat m2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow></TableRow>
                        </TableBody>
                    </Table>
                </Paper>
                <LinearProgress colors="default" />   
            </div>
        );
    }else{
        return(
            <div className="container pad-bot" ref={this.inputContainer}>
                <Typography variant="h4" gutterBottom>
                    Kvm
                </Typography>
                <br/>
                <Paper className="paper-main">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Artikelnummer</TableCell>
                                <TableCell align="left">Namn</TableCell>
                                <TableCell align="right">Otorkat m2</TableCell>
                                <TableCell align="right">Torkat m2</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {ProductFeed}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
            );
        }
    }
};

export default PageHistory;
